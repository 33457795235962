<template>
  <div>
    <div
      class="modal"
      v-for="item in phone"
      :key="item.id"
      :id="`formAttend-${item.id}`"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header" style="border: 0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-dark section-show py-3">
            <h3 class="text-center mb-4">Konfirmasi ke {{ item.name }}</h3>
            <form action="#" class="w-100">
              <div class="mb-3">
                <label for="attendance" class="form-label"
                  >Informasi Kehadiran</label
                >
                <select
                  :id="`attendance-${item.id}`"
                  class="form-select"
                  v-model="form.attendances"
                >
                  <option value="" selected>Pilih Kehadiran</option>
                  <option value="Akan Hadir">Akan Hadir</option>
                  <option value="Tidak Hadir">Tidak Hadir</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="name" class="form-label">Nama</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nama Anda"
                  :id="`name-${item.id}`"
                  v-model="form.name"
                />
              </div>
              <div class="mb-3">
                <label for="total" class="form-label">Jumlah Tamu</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Jumlah Tamu Yang Akan Hadir"
                  :id="`total-${item.id}`"
                  v-model="form.total"
                />
              </div>
              <div class="d-grid">
                <a
                  :href="`https://wa.me/${item.number}?text=${form.name}%20${form.attendances}%20dengan%20jumlah%20${form.total}`"
                  class="btn btn-hy-primary"
                >
                  Kirim
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["phone"],
  data() {
    return {
      form: {
        attendances: "",
        name: "",
        total: "",
      },
    };
  },
};
</script>

<style>
</style>